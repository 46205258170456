$wall-plane: url("../../backgrounds/WallPlane.png");
$wall-bar: url("../../backgrounds/WallBar.png");
$wall-foot: url("../../backgrounds/WallFoot.png");
// url('../../backgrounds/default_wall.jpg')
// url('../../backgrounds/default_wall_foot_scaled.jpg')
// url('../../backgrounds/default_walk_ground.jpg')


.horizontal-scroll-container {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
  // cursor: grab;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;

  /* For Internet Explorer and Edge */
  ::-webkit-scrollbar {
    display: none;
  }
}

.vertical-scroll-container {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  // cursor: grab;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;

  /* For Internet Explorer and Edge */
  ::-webkit-scrollbar {
    display: none;
  }
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.vertical-scroll-container::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}


.top-left-corner {
  white-space: nowrap;
  background-size: auto 100%;
  z-index: 20;
  box-shadow: inset 3px 3px 3px red;
}

.top-right-corner {
  white-space: nowrap;
  background-size: auto 100%;
  z-index: 20;
  box-shadow: inset -3px 3px 3px red;
}

.bottom-left-corner {
  white-space: nowrap;
  background-size: auto 100%;
  z-index: 20;
  box-shadow: inset 3px -3px 3px red;
}

.bottom-right-corner {
  white-space: nowrap;
  background-size: auto 100%;
  z-index: 20;
  box-shadow: inset -3px -3px 3px red;
}

.wall-main {
  // background-image: $wall-plane;
  background-repeat: repeat-x;
  white-space: nowrap;
  background-size: auto 100%;
  z-index: 15;
}

.vertical-bar-left {
  background-repeat: repeat-y; // Repeats the background image vertically
  background-size: 100% auto;
  z-index: 20;
  box-shadow:
    inset 3px 0px 3px red,
    10px 0 7px rgba(0, 0, 0, 0.1); // Casts the shadow to the right
}

.vertical-bar-right {
  background-repeat: repeat-y; // Repeats the background image vertically
  background-size: 100% auto;
  z-index: 20;
  box-shadow:
    inset -3px 0px 3px red,
    -3px 0 5px rgba(0, 0, 0, 0.15); // Casts the shadow to the left
}

.wall-top-bar {
  // background-image: $wall-bar;
  background-repeat: repeat-x;
  white-space: nowrap;
  background-size: auto 100%;
  z-index: 20;
  box-shadow:
    inset 0px 3px 3px red,
    0 7px 10px rgba(0, 0, 0, 0.1);
}

.wall-bottom-bar {
  // background-image: $wall-bar;
  background-repeat: repeat-x;
  white-space: nowrap;
  background-size: auto 100%;
  z-index: 20;
  box-shadow:
  inset 0px -3px 3px red,
    0 -3px 5px rgba(0, 0, 0, 0.15);
}

.wall-foot {
  // background-image: $wall-foot;
  background-repeat: repeat-x;
  white-space: nowrap;
  background-size: auto 100%;
  z-index: 0;
}

/* .infinite-wall-foot {
    position: absolute;
    bottom: 0px;
    height: 3vh;
    width: 1000000px;
    white-space: nowrap;
    background-image: url('../../backgrounds/default_wall_foot_scaled.jpg');
    background-repeat: repeat-x;
    background-size: auto 100%;
    z-index: 20;
    box-shadow:
      inset 2px 2px 5px rgba(255, 255, 255, 0.3),
      inset -2px -7px 20px rgba(0, 0, 0, 0.03),
      0 -3px 5px rgba(0, 0, 0, 0.15);
  
    &--top {
      top: 0px;
      height: 2vh;
      box-shadow: 
        inset 2px 2px 5px rgba(255, 255, 255, 0.3),
        inset -2px -7px 20px rgba(0, 0, 0, 0.03),
        0 7px 10px rgba(0, 0, 0, 0.1);
    }
  }*/

.infinite-walk-trail {
  position: absolute;
  bottom: 0vh;
  height: 15vh;
  width: 1000000px;
  white-space: nowrap;
  background-image: url('../../backgrounds/default_walk_ground.jpg');
  background-repeat: repeat-x;
  background-size: auto 100%;
  z-index: 0;
}

.coordinate-display {
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 18px;
  font-weight: bold;
}

.main-canvas {
  position: absolute;
  z-index: 10;
}

.round-cursor {
  position: fixed;
  border-radius: 50%;
  pointer-events: none;
  z-index: 90;
}

.image-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.image_pieces {
  pointer-events: none;
  user-select: none;
  position: absolute;
  z-index: 1;
}