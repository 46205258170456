$red-line-color: #ff7575;
$green-line-color: #7de97d;

.red-lines-horizontal {
  background-color: $red-line-color;
  box-shadow: "inset 0px -2px 2px rgba(0, 0, 0, 0.15)";
  &:hover {
    background-color: darken($red-line-color, 10%);
    transform: translateY(-1px);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  }
}

.green-lines-horizontal {
  background-color: $green-line-color;
  box-shadow: "inset 0px -2px 2px rgba(0, 0, 0, 0.15)";
  &:hover {
    background-color: darken($green-line-color, 20%);
    transform: translateY(-1px);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
  }
}

.red-lines-vertical {
  background-color: $red-line-color;
  box-shadow: "inset -2px 0px 2px rgba(0, 0, 0, 0.15)";
  &:hover {
    background-color: darken($red-line-color, 10%);
    transform: translateY(-1px);
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.5);
  }
}

.green-lines-vertical {
  background-color: $green-line-color;
  box-shadow: "inset -2px 0px 2px rgba(0, 0, 0, 0.15)";
  &:hover {
    background-color: darken($green-line-color, 20%);
    transform: translateY(-1px);
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.5);
  }
}