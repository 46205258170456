$base-color: rgba(128, 128, 128, 1);
$base-color-highlighted: rgba(170, 170, 170, 1);
$text-color: rgb(235, 235, 235, 1);
$text-color-darker: rgb(220, 220, 220);

$border-radius: 20px;

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;

  &-button {
    display: none; // Remove the scrollbar arrows
  }
}

::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.7); // Change the fill color to a grayish tone
  border-radius: 4px;

  &:hover {
    background-color: rgba(100, 100, 100, 0.9); // Make the thumb darker when hovering
  }
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

@mixin shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
}

@mixin base-container-style {
  background-color: $base-color;
  margin-top: 0px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: $border-radius;
  height: 99%;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.1);
}

@mixin soft-edges {
  border-radius: $border-radius;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@mixin panel-title {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
  color: $text-color-darker;
}

@mixin unselectable {
  user-select: none;
  /* Standard syntax */
  -webkit-user-select: none;
  /* For Safari and Chrome */
  -moz-user-select: none;
  /* For Firefox */
  -ms-user-select: none;
  /* For Internet Explorer and Edge (legacy) */
}

@mixin home-button-mid {
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 2px;
  border-radius: $border-radius;
  font-size: 22px;
}

.home-page-horizontal-line {
  border: none;
  border-top: 1px solid #ccc;
  margin-top: 9px;
  margin-bottom: 9px;
  margin-left: 3px;
  margin-right: 3px;
}

.home-list-container {
  @include shadow;
  @include base-container-style;
  height: 100%;
  box-sizing: border-box;

  &--items {
    @include unselectable;
  }

  &--white-text {
    color: white;
    background-color: darken($base-color, 10%);
  }

  &--no-padding {
    padding: 0px;
  }

  .scroll-area {
    overflow-y: auto;
    height: 100%;
  }

  p {
    @include panel-title;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      @include home-button-mid;
      background-color: $base-color;
      color: $text-color;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &.selected {
        background-color: darken($base-color, 10%);
      }

      &:hover {
        background-color: lighten($base-color, 15%);
      }
    }

    .avatar-icon {
      bottom: 50%;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 8px;
    }

    .special {
      @include home-button-mid;
      padding: 8px;
      background-color: $base-color;
      color: $text-color;
      ;
      font-size: 27px;

      &:hover {
        background-color: lighten($base-color, 15%);
      }
    }
  }
}

.home-post-container {
  @include shadow;
  @include base-container-style;
  height: 100%;
  box-sizing: border-box;

  &--unselectable {
    @include unselectable;
  }

  .scroll-area {
    overflow-y: auto;
    height: 100%;
  }

  p {
    @include panel-title;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 0;
    list-style: none;

    li {
      cursor: pointer;
      border-radius: 10px;
      overflow: hidden;

      &:hover {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transform: translateY(-2px);

        .image-post-container {
          filter: brightness(1.1);
        }
      }
    }
  }

  .image-post-container {
    width: 160px;
    height: 160px;
    max-width: 256px;
    max-height: 256px;
    object-fit: cover;
    transition: filter 0.3s ease;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
  }

  .post-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.home-header-container {
  @include shadow;
  @include base-container-style;
  border-radius: 0px 0px $border-radius $border-radius;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.home-window-container {
  @include shadow;
  @include base-container-style;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}