.toolBar-wrapper {
  position: relative;
  z-index: 1000;
  user-select: none;
}

.toolBar {
  position: fixed; // Change from absolute to fixed
  bottom: -50px; // Hide the toolbar initially by moving it 40px up from the bottom
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  display: flex;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 15px;
  padding: 0 8px;
  overflow: hidden;
  user-select: none;
  transition: bottom 0.3s ease-in-out; // Add transition for smooth animation
}

.toolBar.show {
  bottom: 0; // Show the toolbar when the 'show' class is added
}

.toolBarGrid {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toolBarGrid:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.toolIcon {
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  text-align: center;
}

.toolIcon:hover {
  color: #ccc;
}