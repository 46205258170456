// Variables
$base-color: rgba(128, 128, 128, 0.5);
$base-color-solid: rgba(128, 128, 128, 0.7);
$disabled-color: rgba(100, 100, 100, 0.6);
$border-radius: 10px;
$background-image: url("./backgrounds/city1.png");
$background-image-1: url("./backgrounds/city1.png");
$background-image-2: url("./backgrounds/city2.png");
$background-image-3: url("./backgrounds/city3.png");
$background-image-4: url("./backgrounds/city4.png");

@keyframes backgroundAnimation {

  0%,
  100% {
    background-image: $background-image-1;
  }

  25% {
    background-image: $background-image-2;
  }

  50% {
    background-image: $background-image-3;
  }

  75% {
    background-image: $background-image-4;
  }
}

$home-background-image: url("./backgrounds/grid-png.png");


::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;

  &-button {
    display: none; // Remove the scrollbar arrows
  }
}

::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.7); // Change the fill color to a grayish tone
  border-radius: 4px;

  &:hover {
    background-color: rgba(100, 100, 100, 0.9); // Make the thumb darker when hovering
  }
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}


// Mixins
@mixin soft-edges {
  border-radius: $border-radius;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@mixin blur-bk {
  background-position: center;
  background-size: cover;
  filter: blur(5px); // Adjust the blur amount as desired
  opacity: 0.5; // Adjust the opacity to control the blending effect
}

@mixin unselectable {
  user-select: none;
  /* Standard syntax */
  -webkit-user-select: none;
  /* For Safari and Chrome */
  -moz-user-select: none;
  /* For Firefox */
  -ms-user-select: none;
  /* For Internet Explorer and Edge (legacy) */
}

@mixin inner-shadow {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}

.unselectable {
  @include unselectable;
}

.close-button {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgba(255, 0, 0, 0.5);
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.5);
}

.background-image {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-position: center;
  background-size: cover;
  filter: blur(10px);
  opacity: 0.5;
  @include unselectable;

  // Add the animation properties
  animation: backgroundAnimation 24s linear infinite;
}

.blurry-background {
  @include blur-bk;
}

.quill-under-text .ql-container,
.quill-under-text .ql-toolbar {
  border: none !important;
  padding: 0px !important;
}

.home_background-image {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: -1; // Set a negative z-index to ensure the background stays behind the content
  background-image: $home-background-image;
  @include blur-bk;
  @include unselectable;
}

.row-container {
  background-color: $base-color;
  padding: 20px;
  margin: auto;
  @include soft-edges;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);

  &--left-bar {
    position: fixed;
    top: 60px;
    bottom: 70px;
    left: 10px;
    width: 30vh;
    z-index: 1000;
  }

  input {
    width: 90%; // Reduce the width to 90%
    padding: 10px;
    margin: 5px; // Set left and right margins to auto
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
    font-size: 16px;
  }

  button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: darken($base-color, 10%);
    border: none;
    border-radius: $border-radius;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($base-color, 15%);
    }
  }
}

.float-container {
  margin: auto;
  display: flex;
  flex-direction: column;

  &--centered-pop {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 500;
  }

  &--not-selectable {
    @include unselectable;
  }

  input {
    width: 90%; // Reduce the width to 90%
    padding: 10px;
    margin: 5px; // Set left and right margins to auto
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
    font-size: 16px;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    @include unselectable;

    input {
      @include inner-shadow;
    }

    .button--disabled {
      background-color: $disabled-color;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: darken($base-color-solid, 10%);
    border: none;
    border-radius: $border-radius;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    @include unselectable;

    &.selected {
      background-color: darken($base-color, 40%);
    }

    &:hover {
      background-color: darken($base-color-solid, 15%);
    }
  }

  .upload-label {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: darken($base-color-solid, 10%);
    border: none;
    border-radius: $border-radius;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    @include unselectable;

    &.selected {
      background-color: darken($base-color, 40%);
    }

    &:hover {
      background-color: darken($base-color-solid, 15%);
    }
  }
}

.canvas-temp {
  width: 100%;
}

.list-container {
  margin-top: 10px;
  padding: 10px;
  overflow-y: auto;
  height: 100%;
  background-color: $base-color;
  border-radius: $border-radius;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.1);
  @include inner-shadow;

  &--items {
    @include unselectable;
  }

  &--white-text {
    color: white;
    background-color: darken($base-color, 10%);
  }

  &--no-padding {
    padding: 0px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 5px;
      margin-bottom: 2px;
      border-radius: $border-radius;
      background-color: lighten($base-color, 10%);
      color: rgba(255, 255, 255, 0.9);
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &.selected {
        background-color: darken($base-color, 10%);
      }

      &:hover {
        background-color: lighten($base-color, 15%);
      }
    }
  }
}

.search-container {
  display: flex;
  width: 100%;

  input {
    width: calc(90% - 40px);
    padding: 10px;
    margin: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
    font-size: 16px;
    flex-grow: 1;
    @include inner-shadow;
  }

  button {
    width: 40px;
    height: 30px;
    padding: 0;
    margin-left: 5px;
    background-color: darken($base-color, 10%);
    border: none;
    border-radius: $border-radius;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($base-color, 15%);
    }
  }
}


// Styles
.form-container {
  background-color: $base-color;
  padding: 20px;
  margin: auto;
  @include soft-edges;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--log {
    width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 480px) {
      width: 90%;
    }
  }

  .largest-text {
    font-size: 2rem; // Adjust the font size as desired
    font-weight: bold; // You can set the font-weight, if desired
    margin-bottom: 12px; // Add some margin to separate it from the other elements
    text-align: center; // Center the text
  }

  .large-text {
    font-size: 1.5rem; // Adjust the font size as desired
    font-weight: bold; // You can set the font-weight, if desired
    margin-bottom: 12px; // Add some margin to separate it from the other elements
    text-align: center; // Center the text
  }

  .small-text {
    font-size: 14px;
  }

  input {
    width: 90%; // Reduce the width to 90%
    padding: 10px;
    margin: 5px; // Set left and right margins to auto
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: $border-radius;
    @include inner-shadow;
    font-size: 16px;
  }

  button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: darken($base-color, 10%);
    border: none;
    border-radius: $border-radius;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($base-color, 15%);
    }
  }
}

.post-container{
  background-color: $base-color;
  @include soft-edges;
  height: 100%;
  overflow-y: auto;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 0;
    list-style: none;

    li {
      cursor: pointer;
      border-radius: 10px;
      overflow: hidden;

      &:hover {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transform: translateY(-2px);

        .image-post-container {
          filter: brightness(1.1);
        }
      }

      &.selected {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transform: translateY(-2px);
        border: 4px solid rgba(0, 0, 0, 0.6);
      }
    }
  }

  .image-post-container {
    width: 160px;
    height: 160px;
    max-width: 256px;
    max-height: 256px;
    object-fit: cover;
    transition: filter 0.3s ease;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
  }

  .post-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.line-up-buttons-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  /* Grey background with 50% opacity */
  z-index: 1000;
  /* Make sure the overlay is above other elements on the page */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Center the warning pop-up within the overlay */
}

.home-container {
  width: 100vw;
  height: 100vh;
  display: flex;
}


.sticker {
  position: absolute;
  // padding: 1em 1.5em;
  // margin: 2em auto;
  color: #ff0000;
  background: #d6d6d6;
  overflow: hidden;
  z-index: 25;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 5px 5px 0 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  @include unselectable;
}

.sticker:hover {
  transform: scale(1.02);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 80%),
    #d6d6d6;
}

.sticker:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-width: 0 0 16px 16px;
  border-style: solid;
  border-color: #ffffff #ffffff #bbbbbb #bbbbbb;
  background: #c5c5c5;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.3), 1px 1px 1px rgba(0, 0, 0, 0.2);
  display: block;
  transform: rotate(90deg);
  width: 0;
}

.sticker.rounded {
  border-radius: 5px 5px 0 5px;
}

.sticker.rounded:before {
  border-width: 8px;
  border-color: #ffffff rgb(255, 255, 255)ransparent transparent;
  border-radius: 0 0 5px 0;
}

.play-icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px; /* adjust as needed */
  height: 50px; /* adjust as needed */
  transform: translate(-50%, -50%);
  background-image: url('./icons/play-circle-line.svg'); /* replace with path to your play icon image */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5; /* set to desired transparency, where 1 is fully opaque and 0 is fully transparent */
  transition: opacity 0.3s, transform 0.3s;
  pointer-events: none;
}

.sticker:hover .play-icon-container {
  opacity: 0.3;
  transform: translate(-50%, -50%) scale(1.2);
}