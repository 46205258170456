.toolbar-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin: 0 5px;
}

.toolbar-button img {
  width: 12px;
  height: 24px;
}