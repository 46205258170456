.header-wrapper {
  position: relative;
  z-index: 1000;
  user-select: none;
}

.headerBar {
  position: fixed;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  display: flex;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 0 0 15px 15px;
  padding: 0 8px;
  // Remove the following line
  // overflow: hidden;
  transition: top 0.3s ease-in-out;
}

.headerBar.show {
  top: 0;
}

.headerBarGrid {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s;
}

.headerBarGrid:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.headerIcon {
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  text-align: center;
  padding: 6px 12px;
}

.headerThinIcon {
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 36px;
  text-align: center;
  width: 24px;
  height: 24px;
  padding: 1px;
}

.headerIcon.logout-button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 3px;
}

.headerIcon:hover {
  color: #ccc;
}

.vertical-bar {
  display: inline-block;
  background-color: #ffffff;
  width: 1px;
  height: 20px;
  margin: 0 3px;
}

.color-picker-dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000; // Increase the z-index value to a higher number
}